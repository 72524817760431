<template>
    <v-container fill-height fluid grid-list-xl>
        <v-layout justify-center wrap>
            <v-flex xs12 md8 >
                <material-card title="Edit Profile"
                               text="Complete your profile">
                    <v-form>
                        <v-container py-0>
                            <v-layout wrap>
                                <v-flex
                                        xs12
                                        md6
                                >
                                    <v-text-field
                                            label="First Name"
                                            v-model="profile.user.first_name"
                                            class="purple-input"></v-text-field>
                                </v-flex>
                                <v-flex
                                        xs12
                                        md6
                                >
                                    <v-text-field
                                            v-model="profile.user.last_name"
                                            label="Last Name"
                                            class="purple-input"></v-text-field>
                                </v-flex>
                                <v-flex
                                        xs12
                                        md12
                                >
                                    <v-text-field
                                            label="Adress"
                                            v-model="profile.address"
                                            class="purple-input"></v-text-field>
                                </v-flex>
                                <v-flex
                                        xs12
                                        md4>
                                    <v-text-field
                                            v-model="profile.zip_code"
                                            class="purple-input"
                                            label="Zip Code"
                                            type="number"></v-text-field>
                                </v-flex>
                                <v-flex
                                        xs12
                                        md4>
                                    <v-text-field
                                            v-model="profile.phone_extension"
                                            class="purple-input"
                                            label="Phone Ext"
                                            type="number"></v-text-field>
                                </v-flex>

                                <v-flex
                                        xs12
                                        md4>
                                    <v-text-field
                                            v-model="profile.phone"
                                            class="purple-input"
                                            label="Phone No"
                                            type="number"></v-text-field>
                                </v-flex>
                                <v-flex
                                        xs12
                                        text-xs-right
                                >
                                    <v-btn
                                          color="blue-grey darken-4"
                                          block
                                          outlined
                                          @click="save_profile(profile)"
                                        >
                                          Update
                                        </v-btn>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-form>
                </material-card>
            </v-flex>
            <v-flex
                    xs12
                    md4
            >
                <material-card class="v-card-profile">
                    <v-avatar
                            slot="offset"
                            class="mx-auto d-block"
                            size="130"
                    >
                        <img alt="demo"
                             src=""
                        >
                    </v-avatar>
                    <v-card-text class="text-xs-center">
                        <h6 class="category text-gray font-weight-thin mb-3">{{ profile.company_name }}</h6>
                        <h4 class="card-title font-weight-light">
                            {{ profile.first_name }}
                            {{ profile.last_name }}
                        </h4>
                        <p class="card-description font-weight-light">
                            Wellstar is a trading platform for professionals.
                            Simply click the products you're interested in and get a quotation instantly,
                            no waiting, no more struggling to find the right supplier.
                        </p>
                    </v-card-text>
                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                profile:{
                    user:{}
                }
            }
        },
        created () {
            this.$http.get(this.$http.defaults.baseURL + '/user/profile')
                .then((response)=>{
                    console.log(response.data);
                    this.profile = response.data.results[0]
                })
                .catch((error)=>{
                    console.log(error);
                });
        },
        methods: {
            load_profile()
            {

            },
            update_user(user_data)
            {
                console.log(user_data);
                this.$hpatch(
                    this.$http.defaults.baseURL + '/user/user-details/' + user_data.id+'/', user_data).then((response) => {
                        if(typeof response.data !== 'undefined')
                    {

                    }
                });
            },
            save_profile(profile)
            {
                this.$hpatch(
                    this.$http.defaults.baseURL + '/user/profile/' + profile.id, profile).then((response) => {
                        if(typeof response.data !== 'undefined')
                    {
                        this.$notify({
                            title: 'Success:',
                            text: 'Successfully updated user',
                            group: 'notifications',
                            position: 'top right'
                        });
                        let user_data = {
                            id: profile.user.id,
                            first_name: profile.user.first_name,
                            last_name: profile.user.last_name
                        };
                        this.update_user(user_data);
                    }
                });
            }
        }
    }
</script>
